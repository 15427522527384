/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import classnames from 'classnames';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';
import { TertiaryButton } from 'components/uiLibrary/LinkButton';

import { useTranslation } from 'src/i18n';

import { TP } from 'constants/index';

import classes from './OperatorBlock.module.scss';

const OPERATOR_OPTIONS = [
  {
    value: '~',
    label: `${TP}.FN_OR`,
  },
  {
    value: ',',
    label: `${TP}.AND`,
  },
];

const OperatorBlock = ({ value, onSelect }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const [isOpen, setIsOpen] = useState(false);
  const [operator, setOperator] = useState(value || OPERATOR_OPTIONS[0]);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = option => {
    setOperator(option);
    setIsOpen(false);
    if (onSelect) {
      onSelect(option);
    }
  };

  return (
    <div className={classes.operatorBlock}>
      <TertiaryButton styles={{ root: classes.operatorBlockBtn }} onClick={handleToggleDropdown}>
        <Typography size="12" weight="bold" textTransform="uppercase">
          {t(operator.label)}
        </Typography>
        <SpriteIcon icon="chevron_down" size="14" />
      </TertiaryButton>
      {isOpen && (
        <ul className={classes.dropdown}>
          {OPERATOR_OPTIONS.map(option => (
            <li
              key={option.value}
              className={classnames(classes.dropdownItem, {
                [classes.dropdownItem__selected]: option.value === operator.value,
              })}
              onClick={() => handleSelect(option)}
            >
              <Typography size="12" textTransform="uppercase">
                {t(option.label)}
              </Typography>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default OperatorBlock;
