import { useQuery } from 'utils/react-query';
import { queryUniversalSearch, queryUniversalSearchResults } from 'utils/API';
import { createDate } from 'utils/date';

import {
  SEARCH_CATEGORIES_IDS,
  CATEGORY_SEARCH_CONFIG,
  ADVANCED_SEARCH_TABS_NAMES,
  ADVANCED_SEARCH_TABS_NAMES_LABELS,
} from 'constants/search';
import { TP, URL_STATIC_PATHS, SEARCH_ENTITY_TYPE_MAP, ORGANIZATION_TYPE_IDS } from 'constants/index';
import { FILTER_SLUG_TYPE } from 'constants/filters';
import { ATTRIBUTE_TYPES, ENTITY_TYPES, FILTER_TYPES, ROUTE_RESERVED_KEYWORDS } from 'operabase-router/constants';

const CURRENT_YEAR = new Date().getFullYear();

export const useUniversalSearch = (query, queryConfig = {}) =>
  useQuery({
    queryKey: ['GET_UNIVERSAL_SEARCH', query],
    queryFn: async () => queryUniversalSearch(query),
    ...queryConfig,
    select: response => ({
      data: response?.data?.filter(
        item =>
          ![ENTITY_TYPES.CITY, ENTITY_TYPES.COUNTRY, ENTITY_TYPES.REGION, ENTITY_TYPES.VENUE].includes(item.entityType),
      ),
    }),
  });

export const useUniversalSearchResults = (query, queryConfig = {}) =>
  useQuery({
    queryKey: ['GET_UNIVERSAL_SEARCH_RESULTS', query],
    queryFn: async () => queryUniversalSearchResults(query),
    ...queryConfig,
    select: response => ({
      ...response?.data,
      data: response?.data?.data?.filter(
        item =>
          ![ENTITY_TYPES.CITY, ENTITY_TYPES.COUNTRY, ENTITY_TYPES.REGION, ENTITY_TYPES.VENUE].includes(item.entityType),
      ),
    }),
  });

export const processTypeaheadSearch = (
  suggestionsData,
  isSuggestionsLoading,
  queryData,
  isQueryLoading,
  isFilterApplied,
) => {
  const total = queryData?.data?.reduce((prev, cur) => prev + cur.total, 0) || 0;

  if (isSuggestionsLoading || isQueryLoading) {
    return { isLoading: true, suggestions: [], resultGroups: [], total };
  }

  const suggestions = total > 3 && (suggestionsData?.data || []) ? (suggestionsData?.data || []).slice(0, 4) : [];
  const resultGroups = (queryData?.data || []).reduce((acc, resGroup) => {
    acc.push({
      ...resGroup,
      results: isFilterApplied ? resGroup.results || [] : (resGroup.results || []).slice(0, 3),
    });

    return acc;
  }, []);

  return { isLoading: false, suggestions, resultGroups, total };
};

export const section_label_map = {
  All: 'operabase.general.FN_FILTER_ALL',
  productions: 'operabase.general.m_PERF',
  company: 'operabase.general.m_OPCOS',
  festival: 'operabase.general.m_FESTIVALS',
  profile: 'operabase.general.m_ARTISTS',
  agency: 'operabase.general.m_MANAGERS',
  venues: 'operabase.general.m_VENUES',
  work: 'operabase.general.FN_MUSICAL_WORKS_SECTION_NAME',
  videos: 'operabase.general.OPERA_ONLINE',
};

export const section_search_base_path_map = {
  profile: ROUTE_RESERVED_KEYWORDS.artists,
  productions: ROUTE_RESERVED_KEYWORDS.productions,
  organization: ROUTE_RESERVED_KEYWORDS.organisations,
  agency: ROUTE_RESERVED_KEYWORDS.managers,
  work: ROUTE_RESERVED_KEYWORDS.works,
  videos: ROUTE_RESERVED_KEYWORDS.videos,
  composers: ROUTE_RESERVED_KEYWORDS.composers,
  All: ROUTE_RESERVED_KEYWORDS.search,
  unknown: ROUTE_RESERVED_KEYWORDS.search,
};

// TODO: Junaid to handle this in single function to generate urls
export const getSectionLinkGenFn = (type, subEntity) => {
  const baseRoute = section_search_base_path_map[type] || section_search_base_path_map.unknown;
  let path = [
    ROUTE_RESERVED_KEYWORDS.artists,
    ROUTE_RESERVED_KEYWORDS.productions,
    ROUTE_RESERVED_KEYWORDS.works,
    ROUTE_RESERVED_KEYWORDS.search,
    ROUTE_RESERVED_KEYWORDS.organisations,
  ].includes(baseRoute)
    ? null
    : URL_STATIC_PATHS.SEARCH;

  if (ROUTE_RESERVED_KEYWORDS.organisations === baseRoute) {
    path = subEntity;
  }

  const getFiltersProps = query => {
    if ([ROUTE_RESERVED_KEYWORDS.artists, ROUTE_RESERVED_KEYWORDS.organisations].includes(baseRoute)) {
      return {
        filters: {
          [FILTER_SLUG_TYPE[FILTER_TYPES.PAGE]]: 1,
          [FILTER_SLUG_TYPE[FILTER_TYPES.QUERY]]: query || '',
        },
      };
    }
    return { queryParams: { query } };
  };

  return query => ({
    baseRoute,
    path,
    shallow: false,
    onlyLinkProps: true,
    entityType: SEARCH_ENTITY_TYPE_MAP[type],
    ...getFiltersProps(query),
  });
};

export const checkIfSearchQueryIsValid = query => query && query.length > 2;

export const getPlaceHolderText = t =>
  `${t(`${TP}.SEARCH`)}: ${Object.keys(ADVANCED_SEARCH_TABS_NAMES)
    .map(item => {
      if (ADVANCED_SEARCH_TABS_NAMES[item] !== ADVANCED_SEARCH_TABS_NAMES.ALL) {
        return t(ADVANCED_SEARCH_TABS_NAMES_LABELS[ADVANCED_SEARCH_TABS_NAMES[item]]);
      }
      return '';
    })
    .filter(Boolean)
    .join(', ')}`;

export const getPredefinedDates = () => {
  const predefinedDates = [
    { label: `${TP}.ONGOING_AND_UPCOMING`, fromDate: createDate().format('YYYY-MM-DD'), toDate: null },
    {
      label: `${TP}.m_TODAY`,
      fromDate: createDate().format('YYYY-MM-DD'),
      toDate: createDate().format('YYYY-MM-DD'),
    },
    {
      label: `${TP}.m_TOMORROW`,
      fromDate: createDate()
        .add(1, 'day')
        .format('YYYY-MM-DD'),
      toDate: createDate()
        .add(1, 'day')
        .format('YYYY-MM-DD'),
    },
    {
      label: `${TP}.NEXT_15_DAYS`,
      fromDate: createDate().format('YYYY-MM-DD'),
      toDate: createDate()
        .add(15, 'day')
        .format('YYYY-MM-DD'),
    },
    {
      label: `${TP}.NEXT_30_DAYS`,
      day: 30,
      fromDate: createDate().format('YYYY-MM-DD'),
      toDate: createDate()
        .add(30, 'day')
        .format('YYYY-MM-DD'),
    },
    {
      label: `${TP}.NEXT_3_MONTHS`,
      fromDate: createDate().format('YYYY-MM-DD'),
      toDate: createDate()
        .add(90, 'day')
        .format('YYYY-MM-DD'),
    },
  ];

  const yearlyDates = Array.from({ length: CURRENT_YEAR - 1995 }, (_, i) => {
    const year = CURRENT_YEAR - i;
    return {
      label: `${year}`,
      fromDate: createDate(`${year}-01-01`).format('YYYY-MM-DD'),
      toDate: createDate(`${year}-12-31`).format('YYYY-MM-DD'),
    };
  });

  return [...predefinedDates, ...yearlyDates];
};

export const getFilteredSearchOptions = ({ options, categoryId, appliedFilters, selectedOptions }) => {
  if (!options?.length) {
    return [];
  }

  const isAlreadySelected = option => appliedFilters?.some(filter => filter.id === option.id);
  const isValidWhereOption = option =>
    (categoryId === SEARCH_CATEGORIES_IDS.WHERE &&
      [
        ORGANIZATION_TYPE_IDS.COMPANY,
        ORGANIZATION_TYPE_IDS.FESTIVAL,
        ORGANIZATION_TYPE_IDS.ORCHESTRA,
        ORGANIZATION_TYPE_IDS.VENUE,
      ].includes(option?.organizationType?.id)) ||
    [ENTITY_TYPES.REGION, ENTITY_TYPES.COUNTRY, ENTITY_TYPES.CITY].includes(option?.entityType);

  const filteredOptions = options.filter(
    option => !isAlreadySelected(option) && (categoryId !== SEARCH_CATEGORIES_IDS.WHERE || isValidWhereOption(option)),
  );
  const missingSelectedOptions =
    selectedOptions?.filter(
      selected => !options.some(option => option.id === selected.id) && !isAlreadySelected(selected),
    ) || [];

  return [...missingSelectedOptions, ...filteredOptions];
};

const formatGenreOptions = (entityType, id, slug, stagingTypes) =>
  stagingTypes?.length
    ? stagingTypes.map(({ id: formatId, slug: formatSlug }) => ({
        entityType,
        entity: { id, slug },
        format: { id: formatId, slug: formatSlug },
      }))
    : [{ entityType, entity: { id, slug } }];

export const getSearchOptions = categoryDetails =>
  Object.entries(categoryDetails).reduce((acc, [category, { selectedOptions, fromDate, toDate }]) => {
    if (category === SEARCH_CATEGORIES_IDS.DATE) {
      return {
        ...acc,
        date: {
          ...(fromDate && { date_from: fromDate }),
          ...(toDate && { date_to: toDate }),
        },
      };
    }

    acc[category] = selectedOptions?.reduce(
      (categoryAcc, { entityType, id, slug, operator, stagingTypes, country }) => {
        switch (category) {
          case SEARCH_CATEGORIES_IDS.WHO:
          case SEARCH_CATEGORIES_IDS.WHERE:
          case SEARCH_CATEGORIES_IDS.WHAT:
            categoryAcc.push({
              entityType,
              entity: { id, slug },
              ...(operator?.value && { operator: operator.value }),
              ...(entityType === ENTITY_TYPES.CITY && country && { country }),
            });
            break;

          case SEARCH_CATEGORIES_IDS.GENRE:
            categoryAcc.push(...formatGenreOptions(entityType, id, slug, stagingTypes));
            break;

          default:
            break;
        }

        return categoryAcc;
      },
      [],
    );

    return acc;
  }, {});

const getCategoryState = (categoryId, filters) => {
  if (categoryId === SEARCH_CATEGORIES_IDS.DATE) {
    return {
      id: categoryId,
      fromDate: filters?.date?.date_from || null,
      toDate: filters?.date?.date_to || null,
      label: '',
    };
  }

  return {
    id: categoryId,
    query: '',
    tab: CATEGORY_SEARCH_CONFIG[categoryId]?.tabs?.[0],
    selectedOptions: [],
    appliedFilters: [],
  };
};

const getEntityAttributes = ({ entityType, attributes = [], parents = [] }) => {
  const entities = attributes?.[ATTRIBUTE_TYPES.ENTITY];
  if (entities?.length === 0 && parents?.length === 0) {
    return {};
  }

  const birthYear = entities
    ?.find(attr => attr.attributeType === ATTRIBUTE_TYPES.BIRTH_DATE)
    ?.entity?.name?.split('-')?.[0];
  const deathYear = entities
    ?.find(attr => attr.attributeType === ATTRIBUTE_TYPES.DEATH_DATE)
    ?.entity?.name?.split('-')?.[0];
  const personal =
    birthYear || deathYear
      ? { birth: birthYear ? { year: birthYear } : undefined, death: deathYear ? { year: deathYear } : undefined }
      : undefined;

  const entityMapping = {
    [ENTITY_TYPES.PROFILE]: () => ({
      headline: entities
        ?.filter(attr => attr.entityType === ENTITY_TYPES.PROFESSION)
        .map(profession => profession.entity?.name)
        .join(', '),
      ...(personal && { personal }),
    }),
    [ENTITY_TYPES.ORGANIZATION]: () => ({
      city: entities?.find(attr => attr.entityType === ENTITY_TYPES.CITY)?.entity,
      organizationType: entities?.find(attr => attr.entityType === ENTITY_TYPES.ORGANIZATION_TYPE)?.entity,
    }),
    [ENTITY_TYPES.WORK]: () => ({
      composer: entities?.find(attr => attr.entityType === ENTITY_TYPES.PROFILE)?.entity,
      workType: entities?.find(attr => attr.entityType === ENTITY_TYPES.WORK_TYPE)?.entity,
      original_name: attributes?.['original-name'],
    }),
    [ENTITY_TYPES.CITY]: () => ({
      state: entities?.find(attr => attr.entityType === ENTITY_TYPES.STATE)?.entity,
      country: parents?.find(attr => attr.entityType === ENTITY_TYPES.COUNTRY)?.entity,
    }),
  };

  return entityMapping[entityType]?.() || {};
};

export const getSearchStateFromFilters = (filters = {}) => {
  const state = Object.values(SEARCH_CATEGORIES_IDS).reduce((acc, categoryId) => {
    acc[categoryId] = getCategoryState(categoryId, filters);
    return acc;
  }, {});

  if (filters?.date?.date_from) {
    const predefinedDates = getPredefinedDates();
    const matchingDate = predefinedDates.find(
      ({ fromDate, toDate }) =>
        fromDate === filters.date.date_from &&
        ((toDate && toDate === filters.date.date_to) || (!toDate && !filters.date.date_to)),
    );

    if (matchingDate) {
      state[SEARCH_CATEGORIES_IDS.DATE].label = matchingDate.label;
    }
  }

  const modifiedFilters = Object.fromEntries(
    Object.entries(filters).filter(([key]) => Object.values(SEARCH_CATEGORIES_IDS).includes(key)),
  );

  Object.entries(modifiedFilters).forEach(([categoryId, categoryFilters]) => {
    if (categoryId !== SEARCH_CATEGORIES_IDS.DATE && Array.isArray(categoryFilters) && categoryFilters.length > 0) {
      const groupedEntities = categoryFilters.reduce(
        (acc, { entity, entityType, operator, format, attributes, parents }) => {
          const key = `${entity.id}-${entity.slug}`;

          if (!acc[key]) {
            const entityAttributes = getEntityAttributes({ entityType, attributes, parents });

            acc[key] = {
              ...entity,
              entityType,
              ...(operator && {
                operator: {
                  value: operator,
                  label: operator === '~' ? `${TP}.FN_OR` : `${TP}.AND`,
                },
              }),
              ...entityAttributes,
            };
          }

          if (format) {
            acc[key].stagingTypes = [
              {
                ...format,
                entityType: ENTITY_TYPES.STAGING_TYPE,
              },
            ];
          }

          return acc;
        },
        {},
      );

      const selectedOptions = Object.values(groupedEntities);

      Object.assign(state[categoryId], {
        selectedOptions,
        appliedFilters: selectedOptions,
      });
    }
  });

  return state;
};
