import React, { useMemo } from 'react';
import classnames from 'classnames';

import Typography from 'components/uiLibrary/Typography';
import LinkButton from 'components/uiLibrary/LinkButton';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';

import usePageContext from 'utils/hooks/usePageContext';
import { useTranslation } from 'src/i18n';

import { TP } from 'constants/index';
import { ENTITY_TYPES } from 'operabase-router/constants';

import classes from './SearchResultCard.module.scss';

const Card = ({ entity, entityType, isSelected, className, typographyProps = {} }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { title, subTitle, subText } = useMemo(() => {
    if (!entity) return {};

    const getCityCountry = () => [entity?.city?.name, entity?.country?.name].filter(Boolean).join(', ');

    switch (entityType) {
      case ENTITY_TYPES.PROFILE: {
        const { birth: { year: birthYear } = {}, death: { year: deathYear } = {} } = entity?.personal || {};
        const birthDeathInfo = birthYear && deathYear ? `${birthYear}-${deathYear}` : '';

        return {
          title: entity?.name,
          subTitle: entity?.headline,
          subText: birthDeathInfo,
        };
      }
      case ENTITY_TYPES.ORGANIZATION: {
        return {
          title: entity?.name,
          subTitle: getCityCountry(),
          subText: entity?.organizationType?.name || '',
        };
      }
      case ENTITY_TYPES.WORK: {
        const workName = entity?.original_name || entity?.name || '';
        const translatedName = entity?.name === entity?.original_name ? '' : entity?.name;

        return {
          title: `${workName} ${translatedName ? `(${translatedName})` : ''}`,
          subTitle: entity?.composer?.name,
          subText: entity?.workType?.name || '',
        };
      }
      case ENTITY_TYPES.CITY:
      case ENTITY_TYPES.COUNTRY:
      case ENTITY_TYPES.REGION: {
        const isCity = entityType === ENTITY_TYPES.CITY;

        const getLocationLabel = () => {
          if (isCity) {
            return [entity?.state?.name, entity?.country?.name].filter(Boolean).join(', ');
          }
          return t(entityType === ENTITY_TYPES.REGION ? `${TP}.FN_REGION` : `${TP}.m_COUNTRY`);
        };

        return {
          title: entity?.name,
          subTitle: getLocationLabel(),
          subText: isCity ? t(`${TP}.m_CITY`) : '',
        };
      }
      case ENTITY_TYPES.AGENCY: {
        return {
          title: entity?.name,
          subTitle: entity?.country?.name || t(`${TP}.M_AGENCY`),
        };
      }
      default: {
        return {
          title: entity?.name,
        };
      }
    }
  }, [entity, entityType, t]);
  const { title: titleProps = {} } = typographyProps;
  const { size = 12, weight = 'medium', color = 'primary' } = titleProps;

  return (
    <Typography className={classnames(classes.card, { [classes.card__selected]: isSelected }, className)}>
      <Typography size={size} weight={weight} color={color} className={classes.title}>
        {title}
      </Typography>
      {[subTitle, subText].map(
        (text, index) =>
          text && (
            <Typography key={index} size="11" color="secondary">
              {text}
            </Typography>
          ),
      )}
    </Typography>
  );
};

const SearchResultCard = ({ entityType, entity, isSelected, isLink, className, typographyProps, onClose }) => {
  const { navigate } = usePageContext();

  if (isLink) {
    const linkProps = navigate.getLinkProps({
      entityType: entityType === ENTITY_TYPES.PROFILE ? ENTITY_TYPES.PROFILE : entityType,
      entity,
      onlyLinkProps: true,
    });

    return (
      <LinkButton
        styles={{ root: classes.linkCard }}
        {...linkProps}
        isLink
        variant="native"
        {...(onClose && { onClick: onClose })}
      >
        <Card entityType={entityType} entity={entity} isLink={isLink} isSelected={isSelected} className={className} />
        <SpriteIcon icon="chevron_right" size="14" />
      </LinkButton>
    );
  }

  return (
    <Card
      entityType={entityType}
      entity={entity}
      isSelected={isSelected}
      className={className}
      typographyProps={typographyProps}
    />
  );
};

export default SearchResultCard;
