import React, { useState, useEffect, useMemo } from 'react';

import queries from 'containers/Search/queries';

import { useQuery } from 'utils/react-query';
import { getFilteredSearchOptions } from 'utils/search';
import { useVisitsHistory } from 'utils/search/visitsHistory';

import { SEARCH_CATEGORIES_IDS, CATEGORY_SEARCH_CONFIG } from 'constants/search';
import { AGGREGATION_TYPES, SORT_OPTION_VALUES } from 'constants/index';
import { PRODUCTIONS_AGGREGATIONS } from 'constants/endpointConstants';
import { ENTITY_TYPES } from 'operabase-router/constants';

import CategorySearch from '../CategorySearch';
import DatesSelector from '../DatesSelector';

const CategorySearchWrapper = ({ categoryDetails, handleUpdateCategory, onApply, onClear, onClose }) => {
  const visitHistory = useVisitsHistory();
  const { selectedOptions, tab, query, id: categoryId, appliedFilters } = categoryDetails || {};
  const { tabs } = CATEGORY_SEARCH_CONFIG[categoryId] || {};

  const showOperator = [SEARCH_CATEGORIES_IDS.WHO, SEARCH_CATEGORIES_IDS.WHAT].includes(categoryId);
  const isDateSearch = categoryId === SEARCH_CATEGORIES_IDS.DATE;
  const isGenreSearch = categoryId === SEARCH_CATEGORIES_IDS.GENRE;
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  const history = useMemo(() => {
    if (!visitHistory) return [];

    return visitHistory
      .filter(({ type }) => {
        const isOrganization = type === 'organizations';
        const isProfile = type === 'profiles';

        if (tab?.key !== 'suggested') {
          return false;
        }

        if (categoryId === SEARCH_CATEGORIES_IDS.WHO) {
          return isProfile;
        }

        if (categoryId === SEARCH_CATEGORIES_IDS.WHERE) {
          return isOrganization;
        }

        return false;
      })
      .map(({ entity }) => ({
        ...entity,
        entityType: entity?.organizationType?.id ? ENTITY_TYPES.ORGANIZATION : ENTITY_TYPES.PROFILE,
      }))
      ?.filter(({ name }) => name?.toLowerCase().includes(query?.toLowerCase() || ''));
  }, [visitHistory, tab?.key, categoryId, query]);
  const uniqueOptions = useMemo(() => {
    const existingIds = new Set(options.map(({ id }) => id));

    return [...history.filter(({ id }) => !existingIds.has(id)), ...options];
  }, [history, options]);

  const { refetch: refetchSearchResults } = useQuery(
    queries.getGlobalSearch({
      queryParams: {
        ...(!query?.length && { suggest: true }),
        query,
        ...(tab?.params || {}),
        sort: SORT_OPTION_VALUES.PRODUCTION_COUNT_DESC,
      },
      queryConfig: {
        enabled: ![SEARCH_CATEGORIES_IDS.GENRE, SEARCH_CATEGORIES_IDS.DATE].includes(categoryId),
        onSettled: res => handleFetchSettled(res),
      },
    }),
  );

  const { refetch: refetchGenre } = useQuery(
    queries.getDropDownList({
      endpoint: PRODUCTIONS_AGGREGATIONS,
      queryParams: {
        aggregation_type: AGGREGATION_TYPES.GENRE,
        aggregation_query: query,
        limit: 50,
      },
      queryConfig: {
        enabled: isGenreSearch,
        select: res =>
          res?.data?.data?.map(genre => ({
            ...genre,
            entityType: ENTITY_TYPES.WORK_TYPE,
            stagingTypes:
              genre?.stagingTypes?.map(stagingType => ({
                ...stagingType,
                entityType: ENTITY_TYPES.STAGING_TYPE,
              })) || [],
          })) || [],
        onSettled: res => handleFetchSettled(res),
      },
    }),
  );

  const handleFetchSettled = res => {
    const filteredOptions = getFilteredSearchOptions({
      options: res,
      history,
      categoryId,
      appliedFilters,
      selectedOptions,
    });
    setOptions(filteredOptions);
    setLoading(false);
  };

  const onChangeTab = selectedTab => {
    handleUpdateCategory({
      ...categoryDetails,
      tab: selectedTab,
    });
  };

  const onSelectOption = (item, operator) => {
    const isChecked = selectedOptions?.some(option => option.id === item.id);

    if (operator) {
      const updatedItem = { ...item, operator };
      handleUpdateCategory({
        ...categoryDetails,
        selectedOptions: selectedOptions.map(option => (option.id === updatedItem.id ? updatedItem : option)),
      });
      return;
    }

    if (item?.stagingTypes?.length) {
      const updatedOptions = selectedOptions?.filter(option => option.id !== item.id);
      handleUpdateCategory({
        ...categoryDetails,
        selectedOptions: [...updatedOptions, item],
      });
    } else {
      handleUpdateCategory({
        ...categoryDetails,
        selectedOptions: isChecked
          ? selectedOptions.filter(option => option.id !== item.id)
          : [...selectedOptions, item],
      });
    }
  };

  useEffect(() => {
    if (categoryId || query?.length > 2) {
      if (tab) {
        refetchSearchResults();
      }

      if (isGenreSearch) {
        refetchGenre();
      }
    }
  }, [refetchGenre, refetchSearchResults, tab, isGenreSearch, categoryId, query?.length]);

  useEffect(() => {
    if (categoryId) {
      setLoading(true);
      setOptions([]);
    }
  }, [categoryId]);

  if (isDateSearch) {
    return (
      <DatesSelector
        categoryId={categoryId}
        onApply={onApply}
        handleUpdateCategory={handleUpdateCategory}
        categoryDetails={categoryDetails}
        onClear={onClear}
      />
    );
  }

  return (
    <CategorySearch
      tabs={tabs}
      options={uniqueOptions}
      selectedTab={tab}
      onChangeTab={onChangeTab}
      selectedOptions={selectedOptions}
      appliedFilters={appliedFilters}
      onSelectOption={onSelectOption}
      onApply={onApply}
      onClear={onClear}
      loading={loading}
      showOperator={showOperator}
      categoryId={categoryId}
      query={query}
      refetchOptions={isGenreSearch ? refetchGenre : refetchSearchResults}
      onClose={onClose}
    />
  );
};

export default CategorySearchWrapper;
