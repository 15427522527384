import React, { useMemo } from 'react';
import classnames from 'classnames';

import Typography from 'components/uiLibrary/Typography';
import DateRangePicker from 'components/uiLibrary/DateRangePicker';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import { PrimaryButton, TextButton } from 'components/uiLibrary/LinkButton';

import { getPredefinedDates } from 'utils/search';
import { useTranslation } from 'src/i18n';

import { TP } from 'constants/index';

import classes from './DatesSelector.module.scss';

const MIN_DATE = '1996-01-01';
const minDate = new Date(MIN_DATE);

const DatesSelector = ({ handleUpdateCategory, categoryDetails, onApply, onClear, categoryId }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { fromDate, toDate } = categoryDetails || {};
  const DATES = useMemo(() => getPredefinedDates(), []);
  const isValidDate = useMemo(
    () =>
      (fromDate || toDate) &&
      (!fromDate || !toDate || fromDate <= toDate) &&
      (!fromDate || new Date(fromDate) >= minDate) &&
      (!toDate || new Date(toDate) >= minDate),
    [fromDate, toDate],
  );

  const handleDateChange = (date, dateType) => {
    const updatedDate = dateType
      ? {
          [`${dateType}Date`]: date,
          label: '',
        }
      : date;
    handleUpdateCategory({
      ...categoryDetails,
      ...updatedDate,
    });
  };

  return (
    <div className={classes.datesSelector}>
      <div className={classes.content}>
        <DateRangePicker date={{ fromDate, toDate }} onChange={handleDateChange} minDate={MIN_DATE} />
        {DATES.map(date => (
          <Typography
            size="12"
            className={classnames(classes.card, {
              [classes.card__selected]: fromDate === date.fromDate && toDate === date.toDate,
            })}
            key={date.label}
            onClick={() => handleDateChange(date)}
          >
            {t(date.label)}
          </Typography>
        ))}
      </div>
      <div className={classes.actions}>
        <TextButton
          disableUnderline
          leftIcon={<SpriteIcon icon="replay-bold" size="14" />}
          onClick={() => onClear(categoryId)}
          styles={{ root: classes.clear }}
        >
          <Typography color="secondary" size="12" className={classes.clear__label} disabled={!isValidDate}>
            {t(`${TP}.FORM_CLEAR_ALL`)}
          </Typography>
        </TextButton>
        <PrimaryButton styles={{ root: classes.submit }} onClick={onApply} disabled={!isValidDate}>
          {t(`${TP}.m_APPLY`)}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default DatesSelector;
