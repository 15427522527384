import React, { useMemo } from 'react';
import classnames from 'classnames';
import times from 'lodash/times';

import Typography from 'components/uiLibrary/Typography';
import Checkbox from 'components/uiLibrary/Inputs/Checkbox';
import { PrimaryButton, TertiaryButton, TextButton } from 'components/uiLibrary/LinkButton';
import HorizontalScroller from 'components/uiLibrary/HorizontalScroller';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Skeleton from 'components/uiLibrary/Loaders/Skeleton';

import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import usePageContext from 'utils/hooks/usePageContext';
import { useTranslation } from 'src/i18n';

import { TP } from 'constants/index';
import { SEARCH_CATEGORIES_IDS } from 'constants/search';
import { ENTITY_TYPES, FILTER_TYPES } from 'operabase-router/constants';

import SearchResultCard from '../SearchResultCard';
import OperatorBlock from '../OperatorBlock';
import NoResult from '../NoResult';

import classes from './CategorySearch.module.scss';

const Tabs = ({ tabs, selectedTab, onChangeTab }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');

  if (tabs.length < 2) return null;

  return (
    <HorizontalScroller styles={{ root: classes.scroller, arrow: classes.arrow }}>
      <div className={classes.tabs}>
        {tabs.map(tab => (
          <TertiaryButton
            key={tab?.key}
            onClick={() => onChangeTab(tab)}
            styles={{ root: classnames(classes.tab, { [classes.tab__selected]: tab.key === selectedTab?.key }) }}
          >
            <Typography size="12" className={classes.tab__label}>
              {t(tab?.label)}
            </Typography>
          </TertiaryButton>
        ))}
      </div>
    </HorizontalScroller>
  );
};

const SearchOptions = ({
  loading,
  options,
  selectedOptions,
  appliedFilters,
  onSelectOption,
  showOperator,
  categoryId,
  query,
  refetchOptions,
  selectedTab,
  onClose,
}) => {
  const { navigate } = usePageContext();
  const { t } = useTranslation('NS_APP_GLOBALS');

  if (loading) {
    return times(11, index => <Skeleton key={index} variant="rectangular" height={28} className={classes.skeleton} />);
  }

  if (!options?.length && !appliedFilters?.length && query?.length > 2) {
    const isWhoOrWhere = [SEARCH_CATEGORIES_IDS.WHO, SEARCH_CATEGORIES_IDS.WHERE].includes(categoryId);
    const isNotCityOrCountry = !['cities', 'countries'].includes(selectedTab?.key);
    const showAddEntity = isWhoOrWhere && isNotCityOrCountry;

    return (
      <NoResult category={categoryId} query={query} refetchOptions={refetchOptions} showAddEntity={showAddEntity} />
    );
  }

  return options?.map((item, index) => {
    const entityType = item?.entityType;
    const linkProps = navigate.getLinkProps({
      entity: item,
      entityType,
      onlyLinkProps: true,
      ...(entityType === ENTITY_TYPES.CITY && { country: item.country }),
    });
    const isSelected = selectedOptions?.some(option => option.id === item.id);
    const showStagingTypes = entityType === ENTITY_TYPES.WORK_TYPE && isSelected && item?.stagingTypes?.length > 0;
    const showOperatorBlock =
      showOperator && selectedOptions?.length > 1 && isSelected && item?.id !== selectedOptions[0]?.id;
    const showOpenPage = selectedOptions?.length <= 1;

    return (
      <div key={index}>
        <div className={classes.item}>
          <div className={classes.item__content}>
            {showOperatorBlock && (
              <OperatorBlock value={item.operator} onSelect={value => onSelectOption(item, value)} />
            )}
            <Checkbox
              onChange={() => {
                if (categoryId === SEARCH_CATEGORIES_IDS.GENRE) {
                  const itemWithoutStagingTypes = {
                    ...item,
                    stagingTypes: [],
                  };
                  onSelectOption(itemWithoutStagingTypes);
                } else {
                  const operator =
                    showOperator && selectedOptions?.length
                      ? item?.operator ?? {
                          value: '~',
                          label: t(`${TP}.FN_OR`),
                        }
                      : null;
                  onSelectOption({
                    ...item,
                    operator,
                  });
                }
              }}
              checked={isSelected}
              size="small"
              className={classnames(classes.checkbox, {
                [classes.checkboxCenterAlign]: entityType === ENTITY_TYPES.WORK_TYPE,
              })}
              label={
                <SearchResultCard
                  entity={item}
                  entityType={entityType}
                  className={classes.item__entity}
                  isSelected={isSelected}
                />
              }
            />
          </div>
          {showOpenPage && (
            <TextButton disableUnderline styles={{ root: classes.openPage }} {...linkProps} isLink onClick={onClose}>
              <Typography size="11" color="secondary">
                {t(`${TP}.OPEN_PAGE`)}
                <SpriteIcon icon="chevron_right" size="12" />
              </Typography>
            </TextButton>
          )}
        </div>
        {showStagingTypes && (
          <div className={classes.stagingTypes}>
            {item?.stagingTypes?.map(stagingType => {
              const selectedOption = selectedOptions?.find(option => option.id === item.id);
              const isStagingTypeSelected = selectedOption?.stagingTypes?.some(type => type?.id === stagingType?.id);
              const stagingTypeLinkProps = navigate.getLinkProps({
                filters: {
                  [FILTER_TYPES.GENRE]: [{ entityType, entity: item, format: stagingType }],
                },
              });
              return (
                <div className={classes.stagingTypeSection} key={stagingType.id}>
                  <Checkbox
                    onChange={() => {
                      onSelectOption({
                        ...item,
                        stagingTypes: isStagingTypeSelected
                          ? selectedOption.stagingTypes.filter(type => type.id !== stagingType.id)
                          : [...selectedOption.stagingTypes, stagingType],
                      });
                    }}
                    checked={isStagingTypeSelected}
                    size="small"
                    className={classnames(classes.checkbox, {
                      [classes.checkboxCenterAlign]: stagingType?.entityType === ENTITY_TYPES.STAGING_TYPE,
                    })}
                    label={
                      <SearchResultCard
                        entity={stagingType}
                        entityType={entityType}
                        className={classes.item__entity}
                        isSelected={isStagingTypeSelected}
                        typographyProps={{ title: { size: 11, color: 'secondary' } }}
                      />
                    }
                  />
                  {showOpenPage && (
                    <TextButton
                      disableUnderline
                      styles={{ root: classes.openPage }}
                      {...stagingTypeLinkProps}
                      isLink
                      onClick={onClose}
                    >
                      <Typography size="11" color="secondary">
                        {t(`${TP}.OPEN_PAGE`)}
                        <SpriteIcon icon="chevron_right" size="12" />
                      </Typography>
                    </TextButton>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  });
};

const CategorySearch = ({
  tabs = [],
  selectedTab,
  onChangeTab,
  options = [],
  selectedOptions = [],
  appliedFilters = [],
  onSelectOption,
  onApply,
  onClear,
  loading,
  showOperator,
  categoryId,
  query,
  refetchOptions,
  onClose,
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { isDesktop } = useDeviceTypeLayouts();
  const total = useMemo(() => {
    if (!selectedOptions) return 0;

    return categoryId === SEARCH_CATEGORIES_IDS.GENRE
      ? selectedOptions.reduce((acc, { stagingTypes }) => acc + (stagingTypes?.length || 1), 0)
      : selectedOptions.length;
  }, [categoryId, selectedOptions]);

  return (
    <div
      className={classnames(classes.categorySearch, {
        [classes.categorySearchWithoutTabs]: tabs?.length < 2,
        [classes.categorySearchMobile]: !isDesktop,
      })}
    >
      <Tabs tabs={tabs} selectedTab={selectedTab} onChangeTab={onChangeTab} />
      <div className={classes.content}>
        {appliedFilters?.length > 0 && (
          <>
            <SearchOptions
              options={appliedFilters}
              selectedOptions={selectedOptions}
              appliedFilters={appliedFilters}
              onSelectOption={onSelectOption}
              showOperator={showOperator}
              categoryId={categoryId}
              onClose={onClose}
            />
            <div className={classes.divider} />
          </>
        )}
        <SearchOptions
          loading={loading}
          options={options}
          selectedOptions={selectedOptions}
          appliedFilters={appliedFilters}
          onSelectOption={onSelectOption}
          showOperator={showOperator}
          categoryId={categoryId}
          query={query}
          refetchOptions={refetchOptions}
          selectedTab={selectedTab}
          onClose={onClose}
        />
      </div>
      <div className={classes.actions}>
        <TextButton
          disableUnderline
          leftIcon={<SpriteIcon icon="replay-bold" size="14" />}
          disabled={!selectedOptions?.length && !appliedFilters?.length}
          onClick={() => onClear(categoryId)}
          styles={{ root: classes.clear }}
        >
          <Typography color="secondary" size="12" className={classes.clear__label}>
            {t(`${TP}.FORM_CLEAR_ALL`)}
          </Typography>
        </TextButton>
        <div>
          {total > 0 && (
            <Typography color="secondary" weight="medium" size="12" textTransform="lowercase">
              {total} {t(`${TP}.FN_SELECTED`)}
            </Typography>
          )}
          <PrimaryButton
            disabled={!selectedOptions?.length && !appliedFilters?.length}
            onClick={onApply}
            styles={{ root: classes.submit }}
          >
            {t(`${TP}.m_APPLY`)}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default CategorySearch;
