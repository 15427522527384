/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment, useState, useMemo, useEffect, useRef } from 'react';
import classnames from 'classnames';

import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Drawer from 'components/uiLibrary/Drawer';
import LinkButton, { TextButton } from 'components/uiLibrary/LinkButton';
import TextField from 'components/uiLibrary/TextField';
import AdvancedSearch from 'components/Search/AdvancedSearch';

import { COMPONENTS, SECTIONS } from 'components/Globals/Analytics/constants';

import { useTranslation, Trans } from 'src/i18n';
import useScrollBlocker from 'utils/hooks/useScrollBlocker';
import { createDate } from 'utils/date';

import { TP, DATE_FORMATS } from 'constants/index';
import { SEARCH_CATEGORIES, SEARCH_CATEGORIES_IDS } from 'constants/search';
import { ENTITY_TYPES } from 'operabase-router/constants';

import CategorySearchWrapper from '../CategorySearchWrapper';

import classes from './MobileSearch.module.scss';

const CURRENT_YEAR = new Date().getFullYear();

const searchTrackingData = {
  section: SECTIONS.GLOBAL_HEADER,
  component: COMPONENTS.GLOBAL_SEARCH,
};

const DrawerHeader = ({ onClose }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');

  return (
    <div className={classes.drawer__header}>
      <SpriteIcon icon="arrow_back" size="16" onClick={onClose} />
      <Typography weight="bold" className={classes.drawer__header_title} truncate>
        {t(`${TP}.SEARCH`)}
        <Typography color="secondary" size="11" className={classes.drawer__header_subTitle} truncate>
          <Trans
            ns="NS_APP_GLOBALS"
            i18nKey={`${TP}.GLOBAL_SEARCH_SUBTITLE`}
            components={{
              span: <span />,
            }}
          />
        </Typography>
      </Typography>
      <SpriteIcon icon="close" size="16" onClick={onClose} />
    </div>
  );
};

const CategoryInput = ({ handleUpdateQuery, categoryDetails }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState(categoryDetails?.query || '');

  useEffect(() => {
    setInputValue(categoryDetails?.query || '');
  }, [categoryDetails?.query]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <TextField
      variant="outlined"
      label={inputValue ? '' : t(`${TP}.TYPE_TO_SEARCH`)}
      value={inputValue}
      onChange={e => {
        setInputValue(e.target.value);
        handleUpdateQuery({ categoryId: categoryDetails.id, query: e.target.value });
      }}
      InputProps={{
        endAdornment: inputValue ? (
          <SpriteIcon
            icon="close"
            size={16}
            className={classes.input__clearIcon}
            onClick={() => {
              setInputValue('');
              handleUpdateQuery({ categoryId: categoryDetails.id, query: '' });
            }}
          />
        ) : (
          <span className={classes.input__searchIcon}>
            <SpriteIcon icon="search" size={16} />
          </span>
        ),
      }}
      fullWidth
      className={classes.input}
      InputLabelProps={{ shrink: false }}
      inputRef={inputRef}
    />
  );
};

const SubTitle = ({ categoryData, category }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { selectedOptions, label, fromDate, toDate } = categoryData;
  const hasSelectedOptions = selectedOptions?.length > 0;
  const isDateCategory = category.id === SEARCH_CATEGORIES_IDS.DATE;
  const hasLabelOrDates = label || fromDate || toDate;

  const getHeadline = option => {
    switch (option?.entityType) {
      case ENTITY_TYPES.PROFILE:
        return option.headline;
      case ENTITY_TYPES.CITY:
        return t(`${TP}.m_CITY`);
      case ENTITY_TYPES.COUNTRY:
        return t(`${TP}.m_COUNTRY`);
      case ENTITY_TYPES.REGION:
        return t(`${TP}.FN_REGION`);
      case ENTITY_TYPES.ORGANIZATION:
        return `${option.organizationType?.name} ${option.city?.name ? `| ${option.city.name}` : ''}`;
      case ENTITY_TYPES.WORK:
        return `${option.workType?.name} | ${option.composer?.name}`;
      case ENTITY_TYPES.WORK_TYPE:
        return option.stagingTypes?.length
          ? option.stagingTypes.map(type => type.name).join(', ')
          : t(`${TP}.FN_GENRE`);
      default:
        return '';
    }
  };

  if (hasSelectedOptions && !isDateCategory) {
    return (
      <Typography truncate className={classes.search__subTitle}>
        {selectedOptions.map((option, index) => {
          const operator = option?.operator?.label ? ` ${t(option.operator.label)} ` : '';
          const headline = getHeadline(option);

          return (
            <Fragment key={option?.id}>
              {operator && index > 0 && (
                <Typography size="10" color="secondary" italic>
                  {operator}
                </Typography>
              )}
              <Typography className={classes.search__value}>
                <Typography size="12" weight="medium">
                  {option.name}
                </Typography>
                {headline && (
                  <Typography size="10" color="secondary">
                    {headline}
                  </Typography>
                )}
              </Typography>
            </Fragment>
          );
        })}
      </Typography>
    );
  }

  if (hasLabelOrDates) {
    const formattedLabel =
      t(label) ||
      `${fromDate ? createDate(fromDate).format(DATE_FORMATS.DAY_SHORT_MONTH_YEAR) : ''}${
        fromDate && toDate ? ' - ' : ''
      }${toDate ? createDate(toDate).format(DATE_FORMATS.DAY_SHORT_MONTH_YEAR) : ''}`;

    return (
      <Typography truncate className={classes.search__value}>
        <Typography size="12" weight="medium">
          {formattedLabel}
        </Typography>
        <Typography size="10" color="secondary">
          {t(`${TP}.m_DATE`)}
        </Typography>
      </Typography>
    );
  }

  return null;
};

const Categories = ({ categoryDetails, setSelectedCategory }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');

  return SEARCH_CATEGORIES.map(category => {
    const categoryData = categoryDetails[category.id] || {};
    const { selectedOptions, label, fromDate, toDate } = categoryData;
    const showAddIcon = selectedOptions?.length > 0;
    const isDateSelected = label || fromDate || toDate;

    return (
      <div className={classes.search__field}>
        <Typography size="12" weight="medium" truncate>
          {t(category.label)}
          {category.id !== SEARCH_CATEGORIES_IDS.DATE && '?'}
          <Typography size="11" color="secondary" className={classes.search__placeholder}>
            {' '}
            {t(category.placeholder, { year: CURRENT_YEAR + 4 })}
          </Typography>
        </Typography>
        <div
          className={classnames(classes.search__input, {
            [classes.search__inputSelected]: selectedOptions?.length > 0 || isDateSelected,
          })}
          onClick={() => setSelectedCategory(category)}
        >
          <SubTitle categoryData={categoryData} category={category} />
          {!showAddIcon && !isDateSelected && (
            <span className={classes.search__searchIcon}>
              <SpriteIcon icon="search" size="16" />
            </span>
          )}
          {showAddIcon && <SpriteIcon icon="add_circle" size="16" className={classes.search__addIcon} />}
        </div>
      </div>
    );
  });
};

const Search = ({
  categoryDetails,
  selectedCategory,
  setSelectedCategory,
  handleUpdateQuery,
  handleUpdateCategory,
  onClear,
  onCloseModal,
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const selectedCategoryDetails = categoryDetails[selectedCategory?.id] || {};

  const onClose = () => {
    setSelectedCategory(null);
  };

  return (
    <div className={classes.search}>
      <AdvancedSearch trackingData={searchTrackingData} />
      <Categories categoryDetails={categoryDetails} setSelectedCategory={setSelectedCategory} />
      {!!selectedCategory?.id && (
        <Drawer isOpen onClose={onClose} className={classes.searchDrawer}>
          <div className={classes.searchDrawer__header}>
            <span className={classes.searchDrawer__header_section}>
              <SpriteIcon icon="arrow_back" size="16" onClick={onClose} />
              <Typography weight="bold" className={classes.searchDrawer__header_title}>
                {t(selectedCategory.label)}
                <Typography size="11" color="secondary" truncate className={classes.searchDrawer__header_placeholder}>
                  {t(selectedCategory.placeholder, { year: CURRENT_YEAR + 4 })}
                </Typography>
              </Typography>
            </span>
            <SpriteIcon icon="close" size="16" onClick={onClose} />
          </div>
          {selectedCategory?.id !== SEARCH_CATEGORIES_IDS.DATE && (
            <CategoryInput categoryDetails={selectedCategoryDetails} handleUpdateQuery={handleUpdateQuery} />
          )}
          <CategorySearchWrapper
            handleUpdateCategory={handleUpdateCategory}
            categoryDetails={selectedCategoryDetails}
            onApply={onClose}
            onClear={onClear}
            onClose={onCloseModal}
          />
        </Drawer>
      )}
    </div>
  );
};

const MobileSearch = ({
  selectedCategory,
  setSelectedCategory,
  clearSelectedCategory,
  handleSearch,
  handleClear,
  categoryDetails,
  handleUpdateCategory,
  handleUpdateQuery,
  resetState,
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const hasSearchApplied = useMemo(
    () =>
      Object.values(categoryDetails).some(
        category =>
          category?.selectedOptions?.length > 0 ||
          category?.appliedFilters?.length > 0 ||
          !!category?.fromDate ||
          !!category?.toDate,
      ),
    [categoryDetails],
  );
  const [open, setOpen] = useState(false);

  const onClose = () => {
    resetState();
    clearSelectedCategory();
    setOpen(false);
  };

  const onClear = categoryId => {
    handleClear(categoryId);
  };

  const formatCategoryLabel = (category, details) => {
    if (!details) return t(category.label);

    const { selectedOptions, label, fromDate, toDate } = details;
    const isDateCategory = category.id === SEARCH_CATEGORIES_IDS.DATE;
    const isSelected = isDateCategory ? label || fromDate || toDate : selectedOptions?.length > 0;

    if (!isSelected) return t(category.label);

    return isDateCategory
      ? t(label) || `${fromDate} - ${toDate}`
      : selectedOptions?.map(option => option?.name)?.join(' ,');
  };

  useScrollBlocker(open);

  return (
    <>
      <div className={classes.mobileSearch} onClick={() => setOpen(true)}>
        <span className={classes.mobileSearch__searchSection}>
          <SpriteIcon icon="search" size="14" />
        </span>
        <Typography size="12" color="tertiary" weight="bold" className={classes.mobileSearch__placeholder}>
          {SEARCH_CATEGORIES.map(category => {
            const name = formatCategoryLabel(category, categoryDetails[category.id]);
            const isSelected = name !== t(category.label);

            return (
              <Typography key={category.id} size="12" color={isSelected ? 'secondary' : 'tertiary'} weight="bold">
                {name}
              </Typography>
            );
          }).reduce((elements, current, index) => (index === 0 ? [current] : [...elements, ' / ', current]), [])}
        </Typography>
      </div>
      {open && (
        <Drawer isOpen onClose={onClose} className={classes.drawer}>
          <div className={classes.drawer__content}>
            <DrawerHeader onClose={onClose} />
            <div className={classes.drawer__searchSection}>
              <Search
                categoryDetails={categoryDetails}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                handleUpdateCategory={handleUpdateCategory}
                handleUpdateQuery={handleUpdateQuery}
                onClear={onClear}
                onCloseModal={onClose}
              />
            </div>
            <div className={classes.drawer__actions}>
              <TextButton
                styles={{ root: classes.clearBtn }}
                disableUnderline
                onClick={() => onClear()}
                disabled={!hasSearchApplied}
              >
                <SpriteIcon icon="replay-bold" size="14" />
                <Typography size="14" color="secondary" weight="medium">
                  {t(`${TP}.AS_FILTERS_CLEAR_ALL_BTN`)}
                </Typography>
              </TextButton>
              <LinkButton
                variant="orange"
                shape="rectangle"
                styles={{ root: classes.searchBtn }}
                disableUnderline
                disabled={!hasSearchApplied}
                onClick={() => {
                  handleSearch();
                  onClose();
                }}
              >
                {t(`${TP}.FN_PROCEED`)}
              </LinkButton>
            </div>
          </div>
        </Drawer>
      )}
    </>
  );
};

export default MobileSearch;
