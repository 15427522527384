/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useState, useEffect, Fragment } from 'react';
import classnames from 'classnames';

import { QuaternaryButton, TextButton } from 'components/uiLibrary/LinkButton';
import Popper from 'components/uiLibrary/Popper';
import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';

import useOnClickOutside from 'utils/hooks/useOnClickOutside';
import useScrollBlocker from 'utils/hooks/useScrollBlocker';
import { createDate } from 'utils/date';
import useAppContext from 'utils/hooks/useAppContext';
import { useTranslation } from 'src/i18n';

import { SEARCH_CATEGORIES, SEARCH_CATEGORIES_IDS } from 'constants/search';
import { DATE_FORMATS, TP } from 'constants/index';

import CategorySearchWrapper from '../CategorySearchWrapper';

import classes from './DesktopSearch.module.scss';

const CURRENT_YEAR = new Date().getFullYear();

const CategoryInput = ({ handleUpdateQuery, inputRef, categoryDetails }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const [inputValue, setInputValue] = useState(categoryDetails?.query || '');

  useEffect(() => {
    setInputValue(categoryDetails?.query || '');
  }, [categoryDetails?.query]);

  return (
    <input
      ref={inputRef}
      className={classes.input}
      placeholder={t(`${TP}.TYPE_TO_SEARCH`)}
      value={inputValue}
      onChange={e => {
        setInputValue(e.target.value);
        handleUpdateQuery({ categoryId: categoryDetails.id, query: e.target.value });
      }}
    />
  );
};

const SubTitle = ({ selectedOptions, category, isSelected, label, fromDate, toDate }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const hasSelectedOptions = selectedOptions?.length > 0;
  const isDateCategory = category.id === SEARCH_CATEGORIES_IDS.DATE;
  const hasLabelOrDates = label || fromDate || toDate;

  if (!hasSelectedOptions && (!isSelected || isDateCategory) && !hasLabelOrDates) {
    if (isDateCategory) {
      return (
        <Typography color="tertiary" size="12">
          {t(category.placeholder, { year: CURRENT_YEAR + 4 })}
        </Typography>
      );
    }

    return (
      <Typography color="tertiary" size="12">
        {t(`${TP}.m_ALL`)}
      </Typography>
    );
  }

  if (hasSelectedOptions && !isSelected) {
    const formattedOptions = selectedOptions
      .map((option, index) => {
        const operator = option?.operator?.label ? ` ${t(option.operator?.label)} ` : '';
        const separator = index > 0 && !option?.operator ? ', ' : '';

        return `${separator}${operator}${option?.name || ''}`;
      })
      .join('');

    return (
      <Typography size="12" truncate weight={hasSelectedOptions || hasLabelOrDates ? 'bold' : 'regular'}>
        {formattedOptions}
      </Typography>
    );
  }

  if (hasLabelOrDates) {
    const formattedLabel =
      t(label) ||
      `${fromDate ? createDate(fromDate).format(DATE_FORMATS.DAY_SHORT_MONTH_YEAR) : ''}${
        fromDate && toDate ? ' - ' : ''
      }${toDate ? createDate(toDate).format(DATE_FORMATS.DAY_SHORT_MONTH_YEAR) : ''}`;

    return (
      <Typography size="12" truncate weight={hasSelectedOptions || hasLabelOrDates ? 'bold' : 'regular'}>
        {formattedLabel}
      </Typography>
    );
  }

  return null;
};

const CategoryItem = ({
  category,
  selectedCategory,
  handleCategoryClick,
  handleUpdateQuery,
  inputRef,
  categoryDetails,
  onClose,
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const isSelected = category.id === selectedCategory;
  const { selectedOptions, label, fromDate, toDate } = categoryDetails[category.id];
  const showAddIcon = selectedOptions?.length > 0 && !isSelected;
  const hasSelectedOptionsOrDates = selectedOptions?.length > 0 || label || fromDate || toDate;

  return (
    <div
      key={category.id}
      className={classnames(classes.searchField, {
        [classes.searchField__selected]: isSelected,
      })}
      onClick={e => handleCategoryClick(e, category)}
    >
      <div className={classes.searchField__container}>
        <Typography truncate>
          <Typography size="12" weight={hasSelectedOptionsOrDates ? 'regular' : 'bold'} textTransform="capitalize">
            {t(category.label)}
          </Typography>
        </Typography>
        {category.id !== SEARCH_CATEGORIES_IDS.DATE && isSelected && (
          <CategoryInput
            handleUpdateQuery={handleUpdateQuery}
            inputRef={inputRef}
            categoryDetails={categoryDetails[selectedCategory]}
            onClose={onClose}
          />
        )}
        <SubTitle
          selectedOptions={selectedOptions}
          category={category}
          isSelected={isSelected}
          label={label}
          fromDate={fromDate}
          toDate={toDate}
        />
      </div>
      {showAddIcon && <SpriteIcon icon="add_circle" size="16" className={classes.addIcon} />}
    </div>
  );
};

const Categories = ({ handleCategoryClick, selectedCategory, handleUpdateQuery, inputRef, categoryDetails, onClose }) =>
  SEARCH_CATEGORIES.map((category, index) => (
    <Fragment key={category.id}>
      <CategoryItem
        category={category}
        selectedCategory={selectedCategory}
        handleCategoryClick={handleCategoryClick}
        handleUpdateQuery={handleUpdateQuery}
        inputRef={inputRef}
        categoryDetails={categoryDetails}
        onClose={onClose}
      />
      {index !== SEARCH_CATEGORIES.length - 1 && <div className={classes.divider} />}
    </Fragment>
  ));

const DesktopSearch = ({
  selectedCategory,
  setSelectedCategory,
  clearSelectedCategory,
  handleSearch,
  handleClear,
  categoryDetails,
  handleUpdateCategory,
  handleUpdateQuery,
  handleApply,
  resetState,
}) => {
  const { obRouteContext } = useAppContext();
  const { url } = obRouteContext;
  const searchRef = useRef(null);
  const inputRef = useRef(null);
  const popperRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isHomePage = url === '/';

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleCategoryClick = (e, category) => {
    setSelectedCategory(category.id);
    setAnchorEl(e.currentTarget);
    setOpen(true);

    if (category.input) {
      focusInput();
    }
  };

  const onApply = () => {
    handleApply();
    onClose();
    clearSelectedCategory();
  };

  useEffect(() => {
    if (selectedCategory) {
      focusInput();
    } else {
      onClose();
    }
  }, [selectedCategory]);

  useOnClickOutside(searchRef, event => {
    if (popperRef.current?.contains(event.target) || event.target.closest('[data-ignore-outside-click]')) {
      return;
    }

    setOpen(false);
    resetState();
    clearSelectedCategory();
  });

  useScrollBlocker(open);

  return (
    <>
      <div className={classes.desktopSearch} ref={searchRef}>
        <QuaternaryButton styles={{ root: classes.search }} disableUnderline onClick={handleSearch}>
          <SpriteIcon icon="search" size="16" />
        </QuaternaryButton>
        <Categories
          inputRef={inputRef}
          selectedCategory={selectedCategory}
          onClose={onClose}
          handleCategoryClick={handleCategoryClick}
          handleUpdateQuery={handleUpdateQuery}
          categoryDetails={categoryDetails}
        />
        <div className={classes.actions}>
          <TextButton styles={{ root: classes.clear }} disableUnderline onClick={() => handleClear()}>
            <SpriteIcon icon="replay-bold" size="14" />
          </TextButton>
        </div>
      </div>
      {open && (
        <Popper open anchorEl={anchorEl}>
          <div
            ref={popperRef}
            className={classnames(classes.popperContainer, { [classes.popperContainer_home]: isHomePage })}
          >
            <CategorySearchWrapper
              categoryDetails={categoryDetails[selectedCategory]}
              handleUpdateCategory={handleUpdateCategory}
              onApply={onApply}
              onClear={handleClear}
              onClose={() => {
                onClose();
                clearSelectedCategory();
              }}
            />
          </div>
        </Popper>
      )}
    </>
  );
};

export default DesktopSearch;
