import React, { useState, useMemo, useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';

import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import usePageContext from 'utils/hooks/usePageContext';
import useAppContext from 'utils/hooks/useAppContext';
import { getSearchOptions, getSearchStateFromFilters } from 'utils/search';

import { SEARCH_CATEGORIES_IDS, CATEGORY_SEARCH_CONFIG } from 'constants/search';

import DesktopSearch from './DesktopSearch';
import MobileSearch from './MobileSearch';

const GlobalSearch = () => {
  const { obRouteContext } = useAppContext();
  const filters = obRouteContext?.linkProps?.filters;
  const { isDesktop } = useDeviceTypeLayouts();
  const { navigate, permissions } = usePageContext();
  const { castingToolPermissions, isAdmin } = permissions;
  const hasCastingToolAccess = isAdmin || castingToolPermissions?.hasAccess;
  const hasSearchApplied = useMemo(
    () => Object.values(SEARCH_CATEGORIES_IDS).some(categoryId => filters?.[categoryId]),
    [filters],
  );

  const initialState = useMemo(
    () =>
      Object.keys(SEARCH_CATEGORIES_IDS).reduce((acc, key) => {
        const categoryId = SEARCH_CATEGORIES_IDS[key];
        if (categoryId === SEARCH_CATEGORIES_IDS.DATE) {
          acc[categoryId] = {
            id: categoryId,
            fromDate: null,
            toDate: null,
            label: '',
          };
        } else {
          acc[categoryId] = {
            id: categoryId,
            query: '',
            tab: CATEGORY_SEARCH_CONFIG[categoryId]?.tabs?.[0],
            selectedOptions: [],
          };
        }

        return acc;
      }, {}),
    [],
  );
  const appliedState = useMemo(() => {
    if (hasSearchApplied) {
      return getSearchStateFromFilters(filters);
    }

    return initialState;
  }, [hasSearchApplied, initialState, filters]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryDetails, setCategoryDetails] = useState(appliedState);

  const clearSelectedCategory = useCallback(() => {
    setSelectedCategory(null);
  }, []);

  const resetState = useCallback(() => {
    setCategoryDetails(appliedState);
  }, [appliedState]);

  const handleSearch = useCallback(() => {
    clearSelectedCategory();
    const search = getSearchOptions(categoryDetails);
    const linkProps = navigate.getLinkProps({ filters: search, pro: hasCastingToolAccess });
    navigate.to(linkProps);
  }, [clearSelectedCategory, categoryDetails, navigate, hasCastingToolAccess]);

  const handleApply = useCallback(() => {
    const search = getSearchOptions(categoryDetails);
    const linkProps = navigate.getLinkProps({ filters: search, pro: hasCastingToolAccess });
    navigate.to(linkProps);
  }, [categoryDetails, navigate, hasCastingToolAccess]);

  const handleClear = useCallback(
    categoryId => {
      if (!categoryId) {
        setCategoryDetails(initialState);
        clearSelectedCategory();
        navigate.to(navigate.getLinkProps());
        return;
      }

      const { [categoryId]: _, ...rest } = categoryDetails;
      setCategoryDetails({
        ...rest,
        [categoryId]: initialState[categoryId],
      });

      if (isDesktop) {
        navigate.to(navigate.getLinkProps({ filters: getSearchOptions(rest), pro: hasCastingToolAccess }));
      }
    },
    [categoryDetails, clearSelectedCategory, hasCastingToolAccess, initialState, isDesktop, navigate],
  );

  const handleUpdateQuery = useMemo(
    () =>
      debounce(({ categoryId, query }) => {
        setCategoryDetails(prev => ({
          ...prev,
          [categoryId]: {
            ...prev[categoryId],
            query,
          },
        }));
      }, 300),
    [],
  );

  const handleUpdateCategory = useCallback(
    category => {
      setCategoryDetails(prevDetails => ({
        ...prevDetails,
        [category.id]: category,
      }));
    },
    [setCategoryDetails],
  );

  useEffect(() => {
    setCategoryDetails(appliedState);
  }, [appliedState]);

  const SearchComponent = isDesktop ? DesktopSearch : MobileSearch;

  return (
    <SearchComponent
      selectedCategory={selectedCategory}
      setSelectedCategory={setSelectedCategory}
      clearSelectedCategory={clearSelectedCategory}
      handleSearch={handleSearch}
      handleClear={handleClear}
      categoryDetails={categoryDetails}
      handleUpdateCategory={handleUpdateCategory}
      handleUpdateQuery={handleUpdateQuery}
      handleApply={handleApply}
      resetState={resetState}
    />
  );
};

export default GlobalSearch;
