import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { DayPickerRangeController } from 'react-dates';
import dayjs from 'dayjs';
import InputAdornment from '@mui/material/InputAdornment';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import TextField from 'components/uiLibrary/TextField';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Popper from 'components/uiLibrary/Popper';

import { createDate } from 'utils/date';
import { useTranslation } from 'src/i18n';

import { DATE_FORMATS, TP } from 'constants/index';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import classes from './DateRangePicker.module.scss';

const MonthYearSelect = ({ month, onMonthSelect, onYearSelect, minYear, maxYear }) => {
  const years = Array.from({ length: maxYear - minYear + 1 }, (_, i) => minYear + i);

  return (
    <div className={classes.datePickerSelect}>
      <TextField
        value={month.month()}
        select
        onChange={e => onMonthSelect(month, Number(e.target.value))}
        size="small"
        variant="outlined"
        SelectProps={{ native: true }}
        className={classes.datePickerSelect__month}
      >
        {Array.from({ length: 12 }, (_, i) => (
          <option key={i} value={i}>
            {dayjs()
              .month(i)
              .format('MMMM')}
          </option>
        ))}
      </TextField>
      <TextField
        value={month.year()}
        select
        onChange={e => onYearSelect(month, Number(e.target.value))}
        size="small"
        variant="outlined"
        SelectProps={{ native: true }}
      >
        {years.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </TextField>
    </div>
  );
};

const SingleInputDayPicker = ({ date, onChange, minDate = '1900-01-01', maxDate = dayjs().year() + 5 }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const [dateState, setDateState] = useState({
    fromDate: date.fromDate ? moment(date.fromDate) : null,
    toDate: date.toDate ? moment(date.toDate) : null,
    focusedInput: 'startDate',
  });
  const [showCalendar, setShowCalendar] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const { fromDate, toDate, focusedInput } = dateState;
  const minYear = dayjs(minDate).year();
  const maxYear = typeof maxDate === 'number' ? maxDate : dayjs(maxDate).year();

  const handleInputClick = event => {
    setAnchorEl(event.currentTarget);
    setShowCalendar(!showCalendar);
  };

  const handleDateChange = ({ startDate, endDate }) => {
    setDateState({
      fromDate: startDate,
      toDate: endDate,
      focusedInput: !startDate ? 'startDate' : 'endDate',
    });

    if (startDate && endDate) {
      onChange({
        fromDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
        toDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
        label: '',
      });
      setShowCalendar(false);
    }
  };

  const handleFocusChange = focused => {
    setDateState(prev => ({
      ...prev,
      focusedInput: focused || 'startDate',
    }));
  };

  useEffect(() => {
    setDateState({
      fromDate: date.fromDate ? moment(date.fromDate) : null,
      toDate: date.toDate ? moment(date.toDate) : null,
      focusedInput: 'startDate',
    });
  }, [date.fromDate, date.toDate]);

  const formattedDate = `${fromDate ? createDate(fromDate).format(DATE_FORMATS.DATE_LABEL) : ''}${
    fromDate && toDate ? ' - ' : ''
  }${toDate ? createDate(toDate).format(DATE_FORMATS.DATE_LABEL) : ''}`;

  return (
    <div className={classes.datePicker}>
      <TextField
        label={t(`${TP}.m_DATES`)}
        variant="outlined"
        fullWidth
        value={formattedDate}
        onClick={handleInputClick}
        size="small"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <SpriteIcon icon="calendar_month" style={{ cursor: 'pointer' }} onClick={handleInputClick} />
            </InputAdornment>
          ),
        }}
      />
      {showCalendar && (
        <Popper open anchorEl={anchorEl} placement="bottom-start" disablePortal>
          <ClickAwayListener onClickAway={() => setShowCalendar(false)}>
            <div>
              <DayPickerRangeController
                startDate={fromDate}
                endDate={toDate}
                onDatesChange={handleDateChange}
                focusedInput={focusedInput}
                onFocusChange={handleFocusChange}
                numberOfMonths={1}
                showClearDates
                hideKeyboardShortcutsPanel
                renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
                  <MonthYearSelect
                    month={month}
                    onMonthSelect={onMonthSelect}
                    onYearSelect={onYearSelect}
                    minYear={minYear}
                    maxYear={maxYear}
                  />
                )}
              />
            </div>
          </ClickAwayListener>
        </Popper>
      )}
    </div>
  );
};

export default SingleInputDayPicker;
