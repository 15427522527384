import React, { useState } from 'react';

import Typography from 'components/uiLibrary/Typography';
import { SecondaryButton } from 'components/uiLibrary/LinkButton';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import CreateNewEntityDialog from 'components/Globals/CreateNewEntityDialog';

import { useTranslation } from 'src/i18n';

import { TP } from 'constants/index';
import { ENTITY_TYPES } from 'operabase-router/constants';
import { SEARCH_CATEGORIES_IDS } from 'constants/search';

import classes from './NoResult.module.scss';

const NoResultHeader = ({ query }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');

  return (
    <div className={classes.noResult__header}>
      <Typography size="16" weight="bold">
        &quot;{query}&quot;
      </Typography>
      <Typography color="secondary">{t(`${TP}.NOT_LISTED_ON_PLATFORM`)}</Typography>
    </div>
  );
};

const NoResult = ({ category, query, refetchOptions, showAddEntity = false }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const [open, setOpen] = useState(false);
  const isWhereSearch = category === SEARCH_CATEGORIES_IDS.WHERE;

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    refetchOptions();
    handleClose();
  };

  return (
    <>
      <div className={classes.noResult}>
        <NoResultHeader query={query} />
        {showAddEntity && (
          <SecondaryButton
            leftIcon={<SpriteIcon icon="add_circle" size="16" />}
            styles={{ root: classes.noResult__action }}
            onClick={() => setOpen(true)}
          >
            {t(`${TP}.ADD_MISSING_ENTITY`)}
          </SecondaryButton>
        )}
      </div>
      {open && (
        <CreateNewEntityDialog
          isOpen
          query={query}
          allowedEntityTypes={
            isWhereSearch ? [ENTITY_TYPES.ORGANIZATION] : [ENTITY_TYPES.PROFILE, ENTITY_TYPES.ORGANIZATION]
          }
          onSave={handleSave}
          onClose={handleClose}
          dataAttributes={{ 'data-ignore-outside-click': true }}
        />
      )}
    </>
  );
};

export default NoResult;
